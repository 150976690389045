<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div style="display: flex;flex-direction: row;justify-content: center;">
      <div style="width: 40%">
        <h2>薯条，3岁，蓝色金渐层</h2>
        <div  >
          <img style="object-fit: scale-down;width: 20vmin;height: 20vmin" src="https://msbros.net/online/post/pics/20231214/1702486692203_1.jpg">
        </div>
      </div>

      <div style="width: 40%">
        <h2>猛男，3岁半，缅因串银渐</h2>
        <div> <img style="object-fit: scale-down;width: 20vmin;height: 20vmin"  src="https://msbros.net/online/post/pics/20231214/1702486903847_2.jpeg"></div>
      </div>
    </div>

      <div style="margin-top: 20vmin">
        <h1>🏝️ 岛主 ☀️🏖️ Edison Wong 😎</h1>
        <h3>欢迎收藏本网页，后续将不定期更新🐱薯条🍟和猛男的生活🎉</h3>
      </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
